import React, { Component } from 'react';
import Typist from 'react-typist';

class Contact extends Component {
  constructor() {
    super();
    this.state = {
      emailtitle: '',
      senderemail: '',
      message: '',
      displayMsg: 'Please fill out the form and submit.',
      colorClass: 'text-dark'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showMessage = this.showMessage.bind(this);
  }

  handleChange() {
    if (event.target.value.length < 2) {
      event.target.value = event.target.value.trim();
    }
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { emailtitle, senderemail, message } = this.state;
    const form = {
      emailtitle,
      senderemail,
      message
    };
    fetch('/api/sendemail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(form)
    })
      .then(data => {
        this.showMessage('Successfully sent the message.', 'text-warning', 2000);
        this.setState({
          emailtitle: '',
          senderemail: '',
          message: ''
        });
      })
      .catch(error => {
        console.error('error:', error.message);
      });

  }

  showMessage(msg, color, time) {
    setTimeout(() => {
      this.setState({
        displayMsg: 'Please fill out the form and submit.',
        colorClass: 'text-dark'
      });
    }, time);
    this.setState({
      displayMsg: msg,
      colorClass: color
    });
  }

  render() {
    const { handleChange, handleSubmit } = this;
    const { emailtitle, senderemail, message, displayMsg, colorClass } = this.state;
    return (
      <main className="bg">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <div className="title">
                <h1><Typist><Typist.Delay ms={500} />contact</Typist></h1>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm">
              <div className="row">
                <div className="col-sm">
                  <div className="email">
                    {/* <p>please email me if you have any questions</p> */}
                    <p>email to: <a className="text-dark" href="mailto:omegathrone@omegathrone.com">omegathrone@omegathrone.com</a></p>
                    <p>or</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="send-email">
                <div className="row">
                  <p className={`${colorClass} col-sm`}>{displayMsg}</p>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <form
                      className="send-form"
                      onSubmit={handleSubmit}>
                      <div className="input-group mb-2 contact-ip">
                        <input
                          required
                          type="title"
                          name="emailtitle"
                          value={emailtitle}
                          className="form-control email-title-ip"
                          placeholder="Please type your title."
                          aria-describedby="basic-addon3"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-group mb-2 contact-ip">
                        <input
                          required
                          type="email"
                          name="senderemail"
                          value={senderemail}
                          className="form-control sender-email-ip"
                          placeholder="Please type your email."
                          aria-describedby="basic-addon3"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="send-msg input-group mb-2 contact-ip">
                        <textarea
                          required
                          name="message"
                          value={message}
                          cols="50"
                          rows="7"
                          className="form-control send-msg-ta"
                          placeholder="Please write your message."
                          aria-label="With textarea"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <input
                          type="submit"
                          className="btn btn-sm btn-outline-dark"
                          value="Submit" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Contact;
