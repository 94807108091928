import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <p className="omega-footer">© 2020 <a className="text-dark" href="https://www.heegu.net">heegu.net</a> - created by omegathrone</p>
      </footer>
    );
  }
}

export default Footer;
