import React, { Component } from 'react';
import Typist from 'react-typist';

class Portfolio extends Component {
  render() {
    return (
      <main className="bg">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <div className="title">
                <span className="h1">
                  <Typist>
                    <Typist.Delay ms={500} />
                    portfolio
                  </Typist>
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm">
              <div className="row contents">
                <div className="col-sm img-box-portfolio">
                  <div>
                    <p className="h3 text-dark">
                      Omega Kanban
                    </p>
                  </div>
                  <a
                    href="https://kanban.heegu.net"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src="images/kanban-ss-002.gif"
                      alt="Omega Kanban"
                      className="contents-img-portfolio"
                    />
                  </a>
                  <div className="mt-2">
                    <p>
                      A Kanban board application built with TypeScript, React,
                      MongoDB, Next.js and Material UI
                    </p>
                    <span className="tech">TypeScript</span>
                    <span className="tech">React</span>
                    <span className="tech">MongoDB</span>
                    <span className="tech">Next.js</span>
                    <span className="tech">Material UI</span>
                  </div>
                  <div className="github-link">
                    <a
                      href="https://github.com/heegupark/omega-kanban"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src="images/github-logo-001.png"
                        alt="Omega Kanban"
                        className="github-logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-sm img-box-portfolio">
                  <div>
                    <p className="h3 text-dark">Omega Note</p>
                  </div>
                  <a
                    href="https://note.heegu.net"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src="images/note-ss-001.gif"
                      alt="Omega Note"
                      className="contents-img-portfolio"
                    />
                  </a>
                  <div className="mt-2">
                    <p>
                      A note application created by using TypeScript, React,
                      React Hooks and Material UI wrapped by Amplify
                    </p>
                    <span className="tech">TypeScript</span>
                    <span className="tech">React</span>
                    <span className="tech">React Hooks</span>
                    <span className="tech">Amplify</span>
                    <span className="tech">Material UI</span>
                  </div>
                  <div className="github-link">
                    <a
                      href="https://github.com/heegupark/omega-note-amplify"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src="images/github-logo-001.png"
                        alt="Omega Note"
                        className="github-logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-sm img-box-portfolio">
                  <div>
                    <p className="h3 text-dark">Omega Talk</p>
                  </div>
                  <a
                    href="https://talk.heegu.net"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src="images/talk-ss-001.gif"
                      alt="Omega Talk"
                      className="contents-img-portfolio"
                    />
                  </a>
                  <div className="mt-2">
                    <p>
                      A messenger application developed with TypeScript, React,
                      React Hooks, Next.js and Socket.io
                    </p>
                    <span className="tech">TypeScript</span>
                    <span className="tech">React</span>
                    <span className="tech">Next.js</span>
                    <span className="tech">Socket.io</span>
                    <span className="tech">Node.js</span>
                  </div>
                  <div className="github-link">
                    <a
                      href="https://github.com/heegupark/omega-talk"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src="images/github-logo-001.png"
                        alt="Omega Talk"
                        className="github-logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-sm img-box-portfolio">
                  <div>
                    <p className="h3 text-dark">Omega Ride</p>
                  </div>
                  <a
                    href="https://ride.heegu.net"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src="images/ride-ss-001.gif"
                      alt="Omega Ride"
                      className="contents-img-portfolio"
                    />
                  </a>
                  <div className="mt-2">
                    <p>
                      A ride sharing application built by using React(client),
                      Node.js(server), and Material AI, Bootstrap, React
                      Spring(UI)
                    </p>
                    <span className="tech">React</span>
                    <span className="tech">Material UI</span>
                    <span className="tech">Bootstrap</span>
                    <span className="tech">Node.js</span>
                    <span className="tech">React Spring</span>
                  </div>
                  <div className="github-link">
                    <a
                      href="https://github.com/heegupark/omega-ride"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src="images/github-logo-001.png"
                        alt="Omega Ride"
                        className="github-logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-sm img-box-portfolio">
                  <div>
                    <p className="h3 text-dark">Omega Gram</p>
                  </div>
                  <a
                    href="https://gram.heegu.net"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src="images/gram-ss-001.gif"
                      alt="Omega Gram"
                      className="contents-img-portfolio"
                    />
                  </a>
                  <div className="mt-2">
                    <p>
                      A photo sharing application created by using
                      React(client), Node.js(server), and MongoDB(database)
                    </p>
                    <span className="tech">React</span>
                    <span className="tech">Node.js</span>
                    <span className="tech">MongoDB</span>
                    <span className="tech">socket.io</span>
                    <span className="tech">Bootstrap</span>
                  </div>
                  <div className="github-link">
                    <a
                      href="https://github.com/heegupark/omega-gram"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src="images/github-logo-001.png"
                        alt="Omega Gram"
                        className="github-logo"
                      />
                    </a>
                  </div>
                </div>
                {/* <div className="col-sm img-box-portfolio">
                  <div>
                    <p className="h3 text-dark">Omega Fresh</p>
                  </div>
                  <a
                    href="https://fresh.heegu.net"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src="images/fresh-ss-001.gif"
                      alt="Omega Fresh"
                      className="contents-img-portfolio"
                    />
                  </a>
                  <div className="mt-2">
                    <p>
                      A shopping cart application developed by using
                      React(client), Node.js(server), and PostgreSQL(database)
                    </p>
                    <span className="tech">React</span>
                    <span className="tech">Node.js</span>
                    <span className="tech">PostgreSQL</span>
                    <span className="tech">JavaScript</span>
                    <span className="tech">Bootstrap</span>
                  </div>
                  <div className="github-link">
                    <a
                      href="https://github.com/heegupark/omega-fresh"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src="images/github-logo-001.png"
                        alt="Omega Fresh"
                        className="github-logo"
                      />
                    </a>
                  </div>
                </div> */}
                <div className="col-sm img-box-portfolio">
                  <div>
                    <p className="h3 text-dark">City-scape</p>
                  </div>
                  <a
                    href="https://city.heegu.net"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src="images/city-ss-001.gif"
                      alt="City-scape"
                      className="contents-img-portfolio"
                    />
                  </a>
                  <div className="mt-2">
                    <p>
                      A service providing information search for a city using
                      Open Weather API, Geo Service API and Pixabay API.
                    </p>
                    <span className="tech">JavaScript</span>
                    <span className="tech">HTML</span>
                    <span className="tech">CSS</span>
                    <span className="tech">Bootstrap</span>
                    <span className="tech">jQuery</span>
                  </div>
                  <div className="github-link">
                    <a
                      href="https://github.com/heegupark/city-scape"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src="images/github-logo-001.png"
                        alt="City-scaoe"
                        className="github-logo"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Portfolio;
