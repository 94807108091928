import React, { Component } from 'react';
import Typist from 'react-typist';

class About extends Component {
  render() {
    return (
      <main className="bg">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <div className="title">
                <span className="h1">
                  <Typist>
                    <Typist.Delay ms={500} />
                    about
                  </Typist>
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm">
              <div className="row">
                <div className="col-sm">
                  <div>
                    <p>
                      {
                        'Adventurous Software Engineer who enjoys programming, loves coding, and likes developing services, products and technologies! I always want to be an adventure developer who enjoys difficult challenges to solve complicated problems.'
                      }
                    </p>
                    <p>
                      {
                        'Over 15+ years experience in tech industry, I have worked on various kinds of applications, systems and services using decent programming languages such as React, TypeScript, Redux, Node.js, Next.js, JavaScript, MongoDB, PostgreSQL, ElasticSearch, GraphQL and so on.'
                      }
                    </p>
                    <p>
                      {
                        'I have built applications and services and deployed them using AWS services such as CDK, SAM and Amplify. I believe "Practice makes perfect." and "Attitude is everything."'
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-sm">
              <div className="links">
                <a
                  href="https://www.linkedin.com/in/heegupark"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src="images/linkedin-logo-001.png"
                    alt="Heegu Park's LinkedIn"
                    className="linkedin-logo"
                  />
                </a>
                <a
                  href="https://github.com/heegupark"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src="images/github-logo-001.png"
                    alt="Heegu Park's Github"
                    className="github-logo"
                  />
                </a>
                <a href="files/Resume-HeeguPark.pdf" target="_blank">
                  <img
                    src="images/resume-text-001.png"
                    alt="Heegu Park's Resume"
                    className="resume-text"
                  />
                </a>
              </div>
              <div className="skills-title-box my-3">
                <h3>Skills</h3>
              </div>
              <div className="row skills-img">
                <div className="skills-img-box">
                  <img
                    src="images/react-logo-001.png"
                    alt="React"
                    className="skills-img"
                  />
                </div>
                <div className="skills-img-box">
                  <img
                    src="images/js-logo-001.png"
                    alt="JavaScript"
                    className="skills-img"
                  />
                </div>
                <div className="skills-img-box">
                  <img
                    src="images/ts-logo-001.png"
                    alt="TypeScript"
                    className="skills-img"
                  />
                </div>
                <div className="skills-img-box">
                  <img
                    src="images/node-logo-001.png"
                    alt="Node.js"
                    className="skills-img"
                  />
                </div>
                <div className="skills-img-box">
                  <img
                    src="images/next-logo-001.png"
                    alt="Next.js"
                    className="skills-img"
                  />
                </div>
                <div className="skills-img-box">
                  <img
                    src="images/graphql-logo-001.png"
                    alt="GraphQL"
                    className="skills-img"
                  />
                </div>
                <div className="skills-img-box">
                  <img
                    src="images/material-ui-logo-001.png"
                    alt="Material UI"
                    className="skills-img"
                  />
                </div>
                <div className="skills-img-box">
                  <img
                    src="images/redux-logo-001.png"
                    alt="React Redux"
                    className="skills-img"
                  />
                </div>
                <div className="skills-img-box">
                  <img
                    src="images/elastic-logo-001.png"
                    alt="elasticsearch"
                    className="skills-img"
                  />
                </div>
                <div className="skills-img-box">
                  <img
                    src="images/postgresql-logo-001.png"
                    alt="PostgreSQL"
                    className="skills-img"
                  />
                </div>
                <div className="skills-img-box">
                  <img
                    src="images/mysql-logo-001.png"
                    alt="MySQL"
                    className="skills-img"
                  />
                </div>
                <div className="skills-img-box">
                  <img
                    src="images/mongodb-logo-001.png"
                    alt="MongoDB"
                    className="skills-img"
                  />
                </div>
                <div className="skills-img-box">
                  <img
                    src="images/aws-logo-001.png"
                    alt="Amazon Web Services"
                    className="skills-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default About;
