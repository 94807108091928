import React, { Component } from 'react';
import Typist from 'react-typist';

class Home extends Component {
  render() {
    return (
      <main className="bg">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <div className="row">
                <div className="col-sm">
                  <div className="intro">
                    <h1 className="bigger goldenrodder">Welcome to <Typist><Typist.Delay ms={300} />heegu.net</Typist></h1>
                    <h4 id="title-sub-1" className="bigger goldenrodder"><Typist><Typist.Delay ms={1100} />Practice makes perfect.</Typist></h4>
                    <p>&nbsp;</p>
                    <span className="bigger golder"><Typist><Typist.Delay ms={2800} /><i>I want to put a dent in the universe. - Steve Jobs</i></Typist></span>
                    <span className="bigger golder"><Typist><Typist.Delay ms={6500} /><i>Work Harder. - Bill Gates</i></Typist></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Home;
